import { Component } from "react";
import FirebaseContext from "./Firebase-config";

const collectioName = "gaeste";

class Data extends Component {
  constructor() {
    super();
    this.state = {
      guests: {
        names: [],
        counts: [],
        songs: [],
        codes: [],
        confirms: [],
        ids: [],
      },
    };
  }

  async getData() {
    await this.getDataFromFirestore();
    return this.state.guests;
  }

  saveToFirestore(docID, confirm, count, name, song, code) {
    FirebaseContext.collection(collectioName)
      .doc(docID)
      .set({
        confirm: confirm,
        count: parseInt(count, 10),
        name: name,
        song: song,
        code: parseInt(code, 10),
      });
  }

  async getDataFromFirestore() {
    await FirebaseContext.collection(collectioName)
      .get()
      .then((querySnapshot) => {
        const id = querySnapshot.docs.map((doc) => doc.id);
        const newState = Object.assign({}, this.state);
        querySnapshot.forEach((doc) => {
          newState.guests.names = newState.guests.names.concat([
            doc.data().name,
          ]);
          newState.guests.counts = newState.guests.counts.concat([
            doc.data().count,
          ]);
          newState.guests.songs = newState.guests.songs.concat([
            doc.data().song,
          ]);
          newState.guests.codes = newState.guests.codes.concat([
            doc.data().code,
          ]);
          newState.guests.confirms = newState.guests.confirms.concat([
            doc.data().confirm,
          ]);
          newState.guests.ids = id;
        });
        this.setState(newState);
      });
  }
}

export default Data;
